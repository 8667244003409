import moment from 'moment'

export default {
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatDetailDate(date) {
      return moment(date).format('DD MMMM YYYY')
    },
    formatBirthDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    formatBirthDateTime(datetime) {
      return moment(datetime).subtract(7, 'hours').format('DD/MM/YYYY H:mm:ss')
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    startOfMonth() {
      return moment().startOf('month').format('YYYY-MM-DD')
    },
    checkValue(val) {
      if (!val || val === undefined || val === '') return false
      return true
    },
    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      }
    },
    isNumberFormat(evt) {
      const theEvent = evt || window.event
      let key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
      if (key.length === 0) return
      const regex = /^[0-9\b]+$/
      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
      return true
    },
    loadAlphabet() {
      const a = []
      let i = 'a'.charCodeAt(0)
      const j = 'z'.charCodeAt(0)
      for (; i <= j; ++i) {
        a.push(String.fromCharCode(i))
      }

      return a.map((v, i) => ({
        value: i + 1,
        text: v.toLocaleUpperCase(),
      }))
    },
  },
}
